import { Trans, t } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faUsers } from "@fortawesome/pro-light-svg-icons";
import { faCircleChevronRight, faCircleChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import Slider from "react-slick";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import { theme } from "../../themeV2";
import LandingLayout from "../../components/landings/LandingLayout";
import ProductModal from "../../components/ProductModal";
import DefaultSection from "../../components/landings/DefaultSection";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import heroImg from "../../images/solutions/allfunds-navigator/hero.png";
import sec1Img from "../../images/solutions/allfunds-navigator/sec-1.png";
import sec1IconBlue from "../../images/solutions/allfunds-navigator/sec-1_icon-blue.svg";
import sec1IconGreen from "../../images/solutions/allfunds-navigator/sec-1_icon-green.svg";
import sec1IconPurple from "../../images/solutions/allfunds-navigator/sec-1_icon-purple.svg";
import sec1IconYellow from "../../images/solutions/allfunds-navigator/sec-1_icon-yellow.svg";
import sec1IconOrange from "../../images/solutions/allfunds-navigator/sec-1_icon-orange.svg";
import sec2Img from "../../images/solutions/allfunds-navigator/sec-2.png";
import sec2Icon1 from "../../images/solutions/allfunds-navigator/sec-2_icon-1.png";
import sec2Icon2 from "../../images/solutions/allfunds-navigator/sec-2_icon-2.png";

const metadata = {
  title: "Allfunds Navigator",
  description:
    "Allfunds Navigator, the revolutionary AI-powered platform, empowers you with real-time, actionable insights derived from over €4.5 trillion of data, giving you the edge you need to outperform in today's competitive fund distribution landscape.",
};

const mainColor = theme.palette.colors.purple.main;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCircleChevronRight}
        fontSize={48}
        color={mainColor}
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCircleChevronLeft}
        className="fa-solid"
        fontSize={48}
        color={mainColor}
      />
    </div>
  );
}

const sliderSettings = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: false,
  slidesToShow: 3,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const SEC_1_CARDS_ITEMS = [
  {
    icon: sec1IconPurple,
    color: theme.palette.colors.purple.main,
    title: t`Uncover Hidden Opportunities`,
    description: t`Identify untapped market potential and pinpoint the most promising distributors with AI-powered insights.`,
  },
  {
    icon: sec1IconOrange,
    color: theme.palette.colors.orangeLight.main,
    title: t`Optimize Your Distribution Strategy`,
    description: t`Make data-driven decisions to grow assets, develop winning products, and manage your platform effectively.`,
  },
  {
    icon: sec1IconGreen,
    color: theme.palette.colors.green.main,
    title: t`Access Real-Time Intelligence`,
    description: t`Stay ahead of the curve with the latest market trends and competitor activity, all in one dynamic platform.`,
  },
  {
    icon: sec1IconBlue,
    color: theme.palette.colors.blueLight.main,
    title: t`Boost Efficiency`,
    description: t`Save time with our intuitive interface and assistant, helping you focus on building relationships and driving sales.`,
  },
  {
    icon: sec1IconYellow,
    color: theme.palette.colors.yellow.main,
    title: t`Target the Right Buyers`,
    description: t`Connect with key decision-makers and access detailed profiles to understand their needs and preferences.`,
  },
];

const SEC_2_ITEMS = [
  {
    icon: sec2Icon1,
    description: t`Ask ANA questions in natural language and receive instant, data-driven answers.`,
  },
  {
    icon: sec2Icon2,
    description: t`Get personalized recommendations and proactive notifications based on your specific needs and goals.`,
  },
];

const USER_CASES_ITEMS = [
  {
    title: t`Identify Emerging Trends`,
    description: t`Analyze real-time flow data to spot investment trends as they emerge, giving you a first-mover advantage.`,
  },
  {
    title: t`Grow Assets Under Management`,
    description: t`Target high-potential buyers with detailed profiles and actionable insights, maximizing your sales efforts.`,
  },
  {
    title: t`Optimize Product Development`,
    description: t`Understand buyer preferences and competitor activity to develop and manage a winning product platform.`,
  },
  {
    title: t`Track Competitors`,
    description: t`Monitor your competitors' strategies and performance to stay ahead in the market.`,
  },
  {
    title: t`Enhance Productivity`,
    description: t`Gain immediate access to the insights you need, saving time and increasing efficiency for your entire team.`,
  },
];

function AllfundsNavigator() {
  const [showModal, setShowModal] = useState(false);
  const [subTitle, setSubTitle] = useState(null);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <Stack px={1} height={{ md: "235px", lg: "235px" }}>
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Stack>
    );
  };

  const renderCardGrid = ({ color, icon, title, description }) => {
    return (
      <Grid item xs={12} sm={6} px={1}>
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <LandingLayout seoTitle={metadata.title} seoDescription={metadata.description} seoNoIndex>
      <HeroLanding
        title={<Trans>Unlock the Power
          of Data-Driven
          Fund Distribution</Trans>}
        description={
          <Trans>
            Allfunds Navigator, the revolutionary AI-powered platform, empowers you with real-time, <b>actionable insights</b> derived from over <b>€4.5 trillion of data</b>, giving you the edge you need to outperform in today's competitive fund distribution landscape.
            <br />
            <br />
            In today's competitive fund distribution landscape, relying on guesswork and outdated information is no longer an option.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("Allfunds Navigator")}
        multimedia={heroImg}
        widthImage="100%"
        kind="Allfunds Navigator"
        kindColor={mainColor}
        grid={{
          columnLeft: { md: 5 },
          columnRight: { md: 7 },
        }}
      />
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px"
        py={{ xs: 2, sm: 4, md: 5 }}
      >
        <DefaultSection
          py={0}
          pt={{ xs: 2, sm: 4, md: 5 }}
          color={theme.palette.colors.white.main}
          pretitle={t`Allfunds Navigator`}
          pretitleColor={mainColor}
          title={t`Gain a Competitive Advantage with Allfunds Navigator`}
          multimedia={sec1Img}
          direction="column"
          textAlign="center"
          pretitleAlign="cebter"
          grid={{
            columnLeft: { md: 6 },
            columnRight: { md: 8 },
          }}
        />
        <Container component="section" py={{ xs: 2, sm: 4 }}>
          <Box sx={{ width: "100%", maxWidth: { xs: 300, sm: 480, md: 700, lg: 1056 }, mx: "auto", mb: 2 }} display={{xs: "none", md: "block"}}>
            <CustomSlider {...sliderSettings}>
              {SEC_1_CARDS_ITEMS.map((item, key) => renderCard({ ...item, key }))}
            </CustomSlider>
          </Box>
          <Grid container spacing={1} mt={2} justifyContent="center" display={{md: "none"}}>
            {SEC_1_CARDS_ITEMS.map((item, key) => renderCardGrid({ ...item, key }))}
          </Grid>
          <Stack alignItems="center" mt={2}>{renderRequestButton("Allfunds Navigator")}</Stack>
        </Container>
      </DarkStack>
      <DefaultSection
        direction="row-reverse"
        pretitle={t`Chat Bot`}
        pretitleColor={mainColor}
        title={<Trans>Meet ANA, <br />Your Intelligent Assistant</Trans>}
        description={
          <>
            <Typography>
              <Trans>
                ANA simplifies navigation and empowers you to access critical insights with ease.
              </Trans>
            </Typography>
            <Grid
              container
              spacing={3}
              ml="-36px!important"
              mt="-12px!important"
              pb={{ sm: 2, md: 0 }}
              justifyContent="center"
            >
              {SEC_2_ITEMS.map((item, key) => (
                <Grid item key={key} md={6} xs={12}>
                  <Stack spacing={1}>
                    <img
                      src={item.icon}
                      alt="Allfunds Navigator - Allfunds"
                      width="38"
                      height="38"
                    />
                    <Typography>{item.description}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </>
        }
        multimedia={sec2Img}
        buttons={renderRequestButton("Chat Bot")}
      />
      <Container component="section" pb={{ xs: 2, md: 10, lg: 8 }}>
        <Box
          width="fit-content"
          m={{ xs: "0 auto -90px", md: "0 auto -102.5px" }}
        >
          <UserCasesStack>
            <FontAwesomeIcon icon={faUsers} />
            <Typography>User Cases</Typography>
            <FontAwesomeIcon icon={faArrowDown} />
          </UserCasesStack>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.colors.white.dark,
            padding: "124px 24px 24px",
            marginLeft: "0!important",
            borderRadius: "14px",
            maxWidth: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              marginLeft: "-36px",
            }}
          >
            {USER_CASES_ITEMS.map(({ title, description }) => (
              <Grid item xs={12} sm={4} md={2.4}>
                <Stack spacing={1} component="article">
                  <Typography fontWeight={700}>{title}</Typography>
                  <Typography color={theme.palette.colors.blue.light}>
                    {description}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Allfunds Navigator"
          product="allfunds-navigator"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

const UserCasesStack = styled(Stack)`
            width: 180px;
            height: 180px;
            font-size: 24px;
            position: relative;
            border-radius: 100%;
            justify-content: center;
            border: 12px solid #fff;
            color: ${theme.palette.colors.white.main};
            background-color: ${mainColor};
            ${theme.breakpoints.up("md")} {
            width: 205px;
            height: 205px;
            font-size: 36px;
          }

            p {
            font-weight: 600;
            line-height: 1.1;
            text-align: center;
            margin: 12px 0 8px 0;
                color: ${theme.palette.colors.white.main};
            ${theme.breakpoints.up("md")} {
            font-size: 24px;
          }
          }
            `;

const DarkStack = styled(Stack)`
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
`;

const CustomSlider = styled(Slider)`
    .slick-prev, .slick-next {width: 48px; height: 48px;z-index: 10}
    .slick-prev:before, .slick-next:before {color: ${mainColor};font-size: 48px;opacity: 1; content: ''}
    .slick-prev.slick-disabled, .slick-next.slick-disabled {opacity: 0}
`;

export default AllfundsNavigator;
